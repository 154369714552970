import colors from '../../../style/colors';

// ICON SETTINGS
export const MAP_ICON_SIZE = 45;

// ROUTE SETTINGS
export const MAP_ROUTE_OUTLINE_COLOR = colors.secondary;
export const MAP_ROUTE_ALT_COLOR = colors.primaryDark;

// ZOOM SETTINGS
export const DEFAULT_HELPER_ZOOM = 15;
export const DEFAULT_TRACKING_ZOOM = 16;
export const DEFAULT_DISPATCH_ZOOM = 15;
export const DEFAULT_MALAYSIA_ZOOM = 6;
export const MARKER_SELECTION_ZOOM = 10;
export const DISTRICT_SELECTION_ZOOM = 9;

// MAP SIZING
export const TRACKING_CONTAINER_HEIGHT = 260;

// ROUTING SETTINGS
export const ROUTING_SETTING_VEHICLE_TYPE = 'auto'; // auto = automobile
export const ROUTING_SETTING_SHORTEST_ROUTE = false; //  Do not use shortest route
export const ROUTING_SETTING_DIRECTIONS_TYPE = 'none'; // Return no direction instructions
export const ROUTING_SETTING_VEHICLE_SPEED = 120;
export const ROUTING_SETTING_HIGHWAY_WEIGHTING = 0.3;
export const ROUTING_SETTING_CONFIG = {
  costing: ROUTING_SETTING_VEHICLE_TYPE,
  directions_type: ROUTING_SETTING_DIRECTIONS_TYPE,
  costing_options: {
    auto: {
      top_speed: ROUTING_SETTING_VEHICLE_SPEED,
      use_highways: ROUTING_SETTING_HIGHWAY_WEIGHTING,
      shortest: ROUTING_SETTING_SHORTEST_ROUTE
    }
  }
};

// DISTRICTS
export const DISTRICT_SELECTION_ALL = 'ALL';
export const DISTRICT_SELECTION_KLANG_VALLEY = 'KV';
export const DISTRICT_SELECTION_JOHOR = 'JB';
export const DISTRICT_SELECTION_PENANG = 'PG';
export const DISTRICT_SELECTION_NEGERI_SEMBILAN = 'N9';

// POLYGON SETTINGS
export const POLYGON_OUTSKIRT_SETTINGS = {
  color: '#ffffff',
  weight: 3,
  opacity: 0.8,
  fill: true,
  fillColor: '#6eacf3',
  fillOpacity: 0.5
};
export const POLYGON_CENTRAL_SETTINGS = {
  color: '#ffffff',
  weight: 3,
  opacity: 0.8,
  fill: true,
  fillColor: '#68b3aa',
  fillOpacity: 0.4
};
export const CENTER_POINTS = {
  KV: [3.0567, 101.5851],
  JB: [1.4927, 103.7414],
  PG: [5.2835, 100.4769],
  N9: [2.72593, 101.90332]
};

// GEOCODE STATUS
export const GEOCODE_RESULT_SUCCESS = 'OK';

// MAP URLS
export const MAP_ATTRIBUTION_LINK =
  '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>';
export const OSM_TILE_URL =
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
