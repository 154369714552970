// BULK ORDER STEPS
export const INTRODUCTION = 'introduction';
export const PICKUP_ORDER_DETAILS_1 = 'pickup_order_details_1';
export const PICKUP_ORDER_DETAILS_2 = 'pickup_order_details_2';
export const MANAGE_DROP_OFFS = 'manage_drop_offs';
export const REVIEW_ORDER = 'review_order';

// BULK ORDER ITEM SIZES
export const ITEM_SIZE_EXTRA_SMALL = 'extra_small';
export const ITEM_SIZE_SMALL = 'small';
export const ITEM_SIZE_MEDIUM = 'medium';
export const ITEM_SIZE_LARGE = 'large';

// BULK ORDER ITEM WEIGHTS
export const ITEM_WEIGHT_LIGHTEST = '<1';
export const ITEM_WEIGHT_LIGHT = '1-3';
export const ITEM_WEIGHT_MEDIUM = '3-10';
export const ITEM_WEIGHT_HEAVY = '10-15';

// BULK ORDER VEHICLE CAPACITY
export const VEHICLE_ITEM_CAPACITY_3 = 3;
export const VEHICLE_ITEM_CAPACITY_6 = 6;
export const VEHICLE_ITEM_CAPACITY_12 = 12;
export const VEHICLE_ITEM_CAPACITY_15 = 15;
export const VEHICLE_ITEM_CAPACITY_30 = 30;

// BULK ORDER DROPOFF FILTERS
export const ALL_DROPOFF_FILTER = 'all';
export const ERROR_DROPOFF_FILTER = 'error';
export const COVERAGE_DROPOFF_FILTER = 'coverage';

// BULK ORDER TESTING IDS
export const ALL_CHIP_TEST_ID = 'filter-chip-all';
export const ERROR_CHIP_TEST_ID = 'filter-chip-error';
export const COVERAGE_CHIP_TEST_ID = 'filter-chip-coverage';
export const SENDER_DETAILS_BUTTON_TEST_ID = 'sender-details-button';
export const SENDER_DETAILS_NAME_TEST_ID = 'sender-details-name';
export const SENDER_DETAILS_EMAIL_TEST_ID = 'sender-details-email';
export const FILEINPUT_TEST_ID = 'file-input-csv';

// BULK ORDER VARIABLES
export const NO_CSV_ERRORS = 0;
export const MAX_CSV_ROWS = 501;
export const MAX_DROPOFF_ROWS = 500;
export const ROWS_PER_PAGE = 20;
export const FIRST_TIME_BULK_DISCOUNT = 0.15;
export const MIN_BULK_DROPOFFS = 5;

// BULK ORDER PROCESS STATUSES
export const DROPOFF_STATUS_PENDING_UPLOAD = 'pending_upload';
export const DROPOFF_STATUS_PROCESSING = 'processing';
export const DROPOFF_STATUS_PENDING = 'pending';
export const DROPOFF_STATUS_PROCESSED = 'processed';
export const DROPOFF_STATUS_CANCELLED = 'cancelled';
export const DROPOFF_STATUS_COMPLETED = 'completed';

// BULK ORDER COVERAGE AREAS
export const COVERAGE_AREA_CENTRAL = 'central';
export const COVERAGE_AREA_OUTSKIRTS = 'outskirts';
export const COVERAGE_AREA_OUTSKIRTS_KV = 'outskirts_kv';
export const COVERAGE_AREA_OUTSKIRTS_JB = 'outskirts_jb';
export const COVERAGE_AREA_OUTSKIRTS_PG = 'outskirts_pg';
export const COVERAGE_AREA_OUTSKIRTS_N9 = 'outskirts_n9';

// BULK ORDER STOPS
export const ONE_HUNDRED_FIFTY_STOPS = 150;
export const EIGHTY_ONE_STOPS = 81;
export const SIXTY_ONE_STOPS = 61;
export const FORTY_ONE_STOPS = 41;
export const THIRTY_ONE_STOPS = 31;
export const TWENTY_ONE_STOPS = 21;
export const ELEVEN_STOPS = 11;
export const FIVE_STOPS = 5;

export const VALID_CSV_HEADER = [
  'Receiver Name',
  'Receiver Phone',
  'Address',
  'Unit Block No',
  'Order ID',
  'Description',
  'Sender Name',
  'Sender Email'
];

export const BULK_GSHEET_BLANK_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1pEsv-0McJz4srEn2o5ecTdvWf6fLlTnYWj4vxw5XBm4';
export const BULK_GSHEET_SAMPLE_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1EM6w209FEj_YlaS4KkhgMceBFvsg6e5v6TUhmFoaMDA';
export const BULK_BLANK_CSV_TEMPLATE_URL =
  'https://goget-uploads.s3.ap-southeast-1.amazonaws.com/bulk-order-templates/bulk_order_blank_template.csv';
export const BULK_SAMPLE_CSV_TEMPLATE_URL =
  'https://goget-uploads.s3.ap-southeast-1.amazonaws.com/bulk-order-templates/bulk_order_sample_template.csv';
export const ZENDESK_CSV_HELP_LINK =
  'https://gogetmy.zendesk.com/hc/en-us/articles/115010119107-Bulk-Orders-CSV-Upload-Instructions';
export const BULK_ORDER_FAQ_LINK =
  'https://gogetmy.zendesk.com/hc/en-us/articles/4414951866137-Bulk-Order-Frequently-Asked-Questions-FAQ-';
export const BULK_INTRO_VIDEO_URL =
  'https://goget-uploads.s3.ap-southeast-1.amazonaws.com/pwa-videos/2022.05.23+Bulk+Order+Introduction.mp4';

export const dimensionSelections = [
  {
    id: 1,
    label: 'Extra Small',
    value: ITEM_SIZE_EXTRA_SMALL
  },
  {
    id: 2,
    label: 'Small',
    value: ITEM_SIZE_SMALL
  },
  {
    id: 3,
    label: 'Medium',
    value: ITEM_SIZE_MEDIUM
  },
  {
    id: 4,
    label: 'Large',
    value: ITEM_SIZE_LARGE
  }
];

export const weightSelections = [
  {
    id: 1,
    label: '< 1 kg',
    value: ITEM_WEIGHT_LIGHTEST
  },
  {
    id: 2,
    label: '1 - 3 kg',
    value: ITEM_WEIGHT_LIGHT
  },
  {
    id: 3,
    label: '3 - 10 kg',
    value: ITEM_WEIGHT_MEDIUM
  },
  {
    id: 4,
    label: '10 - 15 kg',
    value: ITEM_WEIGHT_HEAVY
  }
];
